import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'
import FestivalPatron from '../components/Page/FestivalPatron'
import PaypalDonateFormCard from '../components/Form/PaypalDonateFormCard'

const patronsPage = ({data}) => {
	return (
        <Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
				<div className="md:w-1/2">
					<FestivalPatron />
				</div>
				<div className="md:w-1/2 p-4 md:p-12">

					{/*<PaypalDonateFormCard*/}
					{/*	title={`Become a Patron`}*/}
					{/*	initialValue={200}*/}
					{/*	minValue={200}*/}
					{/*	description={(*/}
					{/*		<>*/}
					{/*			<p className={`mb-2`}>We are indebted to all who have supported the Boswell Book Festival over the past ten years in so many different ways. It is only with the help of so many loyal supporters that the Festival has achieved recognition as a cultural event of national importance.</p>*/}
					{/*		</>*/}
					{/*	)}*/}
					{/*	headerClassName={`bg-bbf-yellow`}*/}
					{/*/>*/}

					<PaypalDonateFormCard
						title={`Papyrus Patron`}
						initialValue={500}
						valueText={`£500+`}
						minValue={500}
						description={(
							<ul className={`list-disc list-inside`}>
								<li>Invitation to Patrons Festival Reception</li>
								<li>Priority booking</li>
								<li>2 complimentary tickets for up to 4 events</li>
							</ul>
						)}
						headerClassName={`bg-bbf-yellow`}
					/>

					<div className={`mb-12`}></div>

					<PaypalDonateFormCard
						title={`Parchment Patron`}
						initialValue={375}
						valueText={`£300 - £499`}
						minValue={300}
						maxValue={499}
						description={(
							<ul className={`list-disc list-inside`}>
								<li>Invitation to Patrons Festival Reception</li>
								<li>Priority booking</li>
								<li>Acknowledgement on website</li>
								<li>4 complimentary tickets</li>
							</ul>
						)}
						headerClassName={`bg-yellow-400`}
					/>

					<div className={`mb-12`}></div>

					<PaypalDonateFormCard
						title={`Paper Patron`}
						initialValue={200}
						valueText={`£150 - £299`}
						minValue={150}
						maxValue={299}
						description={(
							<ul className={`list-disc list-inside`}>
								<li>Invitation to Patrons Festival Reception</li>
								<li>Priority booking</li>
								<li>2 complimentary tickets</li>
							</ul>
						)}
						headerClassName={`bg-yellow-500`}
					/>
				</div>
			</section>
			{/*<ContentPlaceholder title="Festival Friends" />*/}
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "banner/Boswell-Book-Festival-Patron.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default patronsPage
