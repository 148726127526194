import React from 'react'
import PaypalDonateFormLink from './PaypalDonateFormLink'

const PaypalDonateFormCard = (props) => {

	return (
		<div className="shadow-lg flex flex-col justify-between">
			<div className={`flex items-center p-4 font-bold uppercase ${props.headerClassName}`}>
				<h2 className="">{props.title}</h2>
				<p className="ml-auto">{props.valueText}</p>
			</div>
			<div className="p-4 bg-white min-h-24">
				<div>{props.description || `We are indebted to all who have supported the Boswell Book Festival over the past ten years in so many different ways. It is only with the help of so many loyal supporters that the Festival has achieved recognition as a cultural event of national importance.`}</div>
			</div>
			<PaypalDonateFormLink {...props} />
		</div>
	)
}

export default PaypalDonateFormCard
