import React, { useState } from 'react'
import { navigate } from 'gatsby'
import GiftIcon from '../Icon/GiftIcon'

const PaypalDonateFormLink = (props) => {

	const [value, setValue] = useState(props.initialValue || '')
	const [formIsValid, setFormIsValid] = useState(true)

	const handleValueChange = (event) => {
		setValue(event.target.value)
		if (value >= minValue && value <= maxValue) {
			setFormIsValid(true)
		} else {
			setFormIsValid(false)
		}
	}

	const minValue = props.minValue || 1
	const maxValue = props.maxValue || 1000000

	const handleButtonClick = (event) => {
		event.preventDefault()
		if (value >= minValue && value <= maxValue) {
			// use gatsby's navigate to behave the same as Link would in this scenario
			navigate(`/donate`, {state: {amount: value, title: props.title || 'Donate form'}})
		}
	}

	return (
		<form className="flex items-center p-2 border-t-2 border-gray-100 bg-white">
			<label htmlFor="donate_amount" className="text-3xl px-2 ml-auto mr-2">£</label>
			<input
				id="donate_amount"
				className="transition-all shrink-0 p-2 w-16 md:w-32 text-xl text-right text-bbf-dark-green shadow-inner
				border-2 border-bbf-dark-green focus:outline-none focus:ring-4 focus:ring-bbf-mid-green focus:ring-opacity-50"
				type="number"
				name="enteredAmount"
				onChange={handleValueChange}
				onKeyUp={handleValueChange}
				placeholder={props.initialValue || ''}
				min={minValue}
				max={maxValue}
				value={value}
				readOnly={props.readOnly || false}
			/>
			<button
				type="button"
				name="paypalSubmit"
				role="link"
				onClick={handleButtonClick}
				className={`${!formIsValid ? `cursor-not-allowed opacity-50` : `` } p-2 px-3 mx-2 shadow uppercase text-lg text-bbf-dark-green border-2 border-bbf-yellow
				bg-bbf-yellow hover:shadow-md hover:border-bbf-dark-green transition-all flex items-center focus:outline-none focus:ring-4 focus:ring-bbf-yellow focus:ring-opacity-50`}
			>
				<span className="mr-2">Donate</span>
				<GiftIcon />
			</button>
		</form>
	)
}

export default PaypalDonateFormLink
