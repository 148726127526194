import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

const BannerImage = (props) => {
	const { image, alt } = props
	return (
		<div className={`border-b-4 border-bbf-dark-green`}>
			<GatsbyImage
				image={image}
				alt={alt ? alt : `Page banner image`}
				objectFit="cover"
				className={`max-h-screen-50`} />
		</div>
    )
}

export default BannerImage
